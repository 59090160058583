<template>
  <div id="quiz-background" />
</template>

<script>
export default {
  name: 'QuizBackground',
}
</script>

<style lang="scss">
  #quiz-background {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    background-image: url('~@/assets/images/pages/background_quiz_001.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
</style>
